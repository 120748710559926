<template>
  <div class="goods-list" v-loading="loading">
    <!-- 导航路径 -->
    <div class="nav-path">
      <router-link to="/">{{$lang('home')}}</router-link>
		  <i class="iconfont icon-arrow-right"></i>
      <ul>
        <li v-if="brandwords">
          <a :title="brandwords">{{brandwords}}</a>
        </li>
        <li v-else v-for="(item,index) in currentLocation" :key="item.id" :class="item.is_link?'':'location-none-path'" @click="skipTo(item)">
          <router-link :to="{ path: '/list-' + item.id + '.html', query: {level: item.level } }" :title="item.name">
              {{item.name}}
          </router-link>
          <i class="iconfont icon-arrow-right" v-if="item.is_link"></i>
        </li>
      </ul>
    </div>

    <!-- 搜索关键字 -->
    <div class="search_bread" v-if="keyword">
      <span class="keyword">{{ keyword}}</span>
    </div>
    <div class="search_bread" v-else-if="!keyword && catewords">
      <span class="keyword">{{ catewords }}</span>
    </div>
    <div class="search_bread" v-else-if="!keyword && brandwords">
      <span class="keyword">{{ brandwords }}</span>
    </div>

		<!-- 品牌过滤记录区 -->
    <div class="attr_filter" v-if="catewords">
      <el-tag type="info" v-if="choosedCategory" closable @close="colseCategory" effect="plain">
        <span v-if="choosedCategory" class="title">{{$lang('common.category')}}：</span>
        {{ choosedCategory.category_name }}
      </el-tag>
			<el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
				<span v-if="choosedBrand" class="title">{{$lang('common.brand')}}：</span>
				{{ choosedBrand.brand_name }}
			</el-tag>
      <span v-for="item in attributeList" :key="item.attr_id">
        <el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
          <span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
          {{ item.selectedValue }}
        </el-tag>
      </span>
    </div>

    <!-- 品牌属性筛选区 -->
    <template>
      <div class="category" v-if="brandList.length || attributeList.length || child_categoryList.length">
        <!-- 分类列表 -->
        <div class="goods-class" v-if="child_categoryList.length>0">
          <div class="title">{{$lang('common.category')}}：</div>
          <ul>
            <li v-for="(item,index) in child_categoryList" :key="index"	@click="onChooseCategory(item)"><a>{{item.category_name}}</a></li>
          </ul>
        </div>
		
        <!-- 品牌 -->
        <div class="brand">
          <div class="table_head">{{$lang('common.brand')}}：</div>
          <div class="table_body">
            <div class="content">
              <ul>
                <li v-for="(item,index) in brandList" :key="index" @click="onChooseBrand(item)">
                  <a>{{item.brand_name}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 属性 -->
        <div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
          <div class="table_head">{{ item.attr_name }}：</div>
          <div class="table_body">
            <div class="content">
              <span v-for="subitem in item.child" :key="subitem.attr_value_id">
                <el-checkbox
                  :label="subitem.attr_value_name"
                  v-if="item.isMuiltSelect"
                  :checked="subitem.selected"
                  @change="setAttrSelectedMuilt(item, subitem)"
                ></el-checkbox>
                <el-link
                  :underline="false"
                  v-else
                  @click="setAttrSelected(item, subitem)"
                >{{ subitem.attr_value_name }}</el-link>
              </span>
            </div>
          </div>
          <div class="table_op">
            <el-button
              size="small"
              icon="el-icon-circle-plus-outline"
              @click="setMuiltChoose(item)"
            >多选</el-button>
          </div>
        </div>
      </div>
    </template>

		<div class="list-wrap">
			<div class="goods-recommended" v-if="cargoList.length">
				<goods-recommend route="goodslist" :page-size="cargoList.length < 5 ? 2 : 5" />
			</div>
			<div class="list-right">
				<!-- 排序筛选区 -->
				<div>
					<div class="sort">
						<div class="item" @click="changeSort('')">
							<div class="item-name">{{$lang('common.comprehensive')}}</div>
						</div>
						<div class="item" @click="changeSort('sale_num')">
							<div class="item-name">{{$lang('common.sales')}}</div>
							<i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<div class="item" @click="changeSort('discount_price')">
							<div class="item-name">{{$lang('common.price')}}</div>
							<i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<!-- <div class="item-other">
							<el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
						</div>-->
						<!-- <div class="item-other">
							<el-checkbox :label="$lang('common.fast')" v-model="is_own"></el-checkbox>
						</div> -->
						<div class="input-wrap">
							<div class="price_range">
								<el-input :placeholder="$lang('common.lowest_price')" size="small" v-model="filters.min_price"></el-input>
								<span>—</span>
								<el-input :placeholder="$lang('common.highest_price')" size="small" v-model="filters.max_price"></el-input>
							</div>
							<el-button plain size="mini" @click="handlePriceRange">{{$lang('common.confirm')}}</el-button>
						</div>
					</div>
				</div>
				<div class="cargo-list" v-if="cargoList.length">
					<div class="goods-info">
						<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" @click="$router.pushToTab({ path: '/product-' + item.goods_id + '.html' })" style="position: relative;">
							<!-- 自定义标签 -->
              <div class="goods-diy diy-discount" v-if="item.price_label">
                <span>{{item.price_label}}</span>
              </div>
              <div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label">
                <span>{{item.stock_label}}</span>
              </div>
              <div class="goods-left-diy diy-own" v-if="item.own_label">
                <span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
              </div>
              <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
							<div class="price-wrap">
								<div class="price">
									<p>{{ siteInfo.price_unit }}</p>
									{{ showPrice(item) }}
									<div class="price-icon-wrap">
										<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('public/uniapp/index/VIP.png')"/>
										<img v-else-if="item.promotion_type==1" :src="$img('public/uniapp/index/discount.png')"/>
									</div>
								</div>
								<div class="market-price">{{ siteInfo.price_unit + item.market_price }}</div>
							</div>
              <div class="goods-name" v-if="current_lang">
                <router-link :to="'/product-' + item.goods_id + '.html?lang='+current_lang" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link>
              </div>
              <div class="goods-name" v-else>
                <router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link>
              </div>
              <div class="goods-selling">{{ item.introduction }}</div>
              <div class="comment-wrap" v-if="item.evaluate > 0">
                  <el-rate v-model="item.evaluate_rate" disabled></el-rate>
                  <span>{{ item.evaluate }} {{$lang('common.evaluate_num')}}</span>
              </div>
              <div class="comment-wrap" v-else></div>
              <div class="saling">
                <div class="sale-num">
                  <span>{{ item.sale_num || 0 }}</span> {{$lang('common.payment_num')}}
                </div>
                <div class="promotion-type" v-if="item.promotion_type == 1">{{$lang('common.limit_discount')}}</div>
              </div>
						</div>
					</div>
					<div class="pager">
						<el-pagination background :pager-count="5" :total="total" :prev-text="$lang('common.previous_page')" :next-text="$lang('common.next_page')" :current-page.sync="currentPage"
						 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
						 hide-on-single-page></el-pagination>
					</div>
				</div>
				<div class="empty" v-else>
					<div class="ns-text-align">{{$lang('no_goods')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BreadCrumbs from "@/components/BreadCrumbs";
	import GoodsRecommend from "@/components/GoodsRecommend";
	import list from "./list";

	export default {
		name: "list",
		components: {
			BreadCrumbs,
			GoodsRecommend
		},
		data: () => {
			return {};
		},
		mixins: [list],
		created() {},
		methods: {
			showPrice(data){
				let price = data.discount_price;
				if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
				return price;
			}
		}
	};
</script>
<style scoped>
	.comment-wrap /deep/ .el-rate__icon {
		margin-right: 0px;
	 }
</style>

<style lang="scss" scoped>
.el-main-full{
	width: 100% !important;
	box-shadow: 0 17px 20px -8px #EEE inset;
}
.padd-10{
		padding: 0 10px;
	}
.goods-list {
  // background: #fff;
  margin: 0 auto;
  width: 1200px;
  padding-top: 10px;
}
.nav-path {
  display: flex;
	align-items: center;
	height: 30px;
	font-size: 12px;
	margin-left: 5px;
	
	.iconfont{
		color: #a3a3a3;
		font-size: 12px;
		padding: 0 5px;
	}
	
	.path-home{
		cursor:pointer;
	}
	a{
		color: #a3a3a3;
		&:hover{
			color: $base-color;
		}
	}
	.location-none-path{
		pointer-events: none;
		cursor:auto!important;
	}
	
	ul{
		display: flex;
		li{
			cursor:pointer;
		}
	}
}
.search_bread {
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
  margin: 0px auto;
  width: 100%;
  padding: 30px 0 15px;
  text-align: center;
  
  // .keyword{
	 //  width: 100%;
	 //  margin: 0 auto;
  // }
}

.selected_border {
  border: 1px solid $base-color;
}

.attr_filter {
  .el-tag {
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 0;
    .title {
      color: $base-color;
      font-size: 15px;
    }
  }
}

.category {
  margin: 0 auto 10px auto;
  border: 1px solid #eeeeee;
  
  //分类列表
  .goods-class{
  	display: flex;
	.title{
		width: 140px;
		min-width: 140px;
		border-right: 1px solid #eeeeee;
		border-bottom: 1px solid #eeeeee;
		padding-left: 10px;
		padding-top: 10px;
		background-color: #f2f6fc;
	}
  	
  	ul{
  		display: flex;
		width: 100%;
		border-bottom: 1px solid #eeeeee;
		
		li{
			cursor:pointer;
			margin: 5px 10px;
		}
  	}
  }
  

  .brand {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;

    &:last-child {
      border-bottom: none;
    }

    .table_head {
      width: 140px;
      min-width: 140px;
      border-right: 1px solid #eeeeee;
      padding-left: 10px;
      padding-top: 10px;
      background-color: #f2f6fc;
    }
    .table_body {
      flex-grow: 1;
      .initial {
        margin: 5px auto 10px 10px;

        span {
          border: 0;
          margin: 0;
          padding: 5px 10px;
          border: 0;
          border-radius: 0;

          &:hover {
            background-color: $base-color;
            color: #ffffff;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // padding: 10px;
        width: 900px;
		    width: 100%;
        .brand-item{
          margin-right: 10px;
          margin-top: 10px;
        }
		
		ul{
			display: flex;
			justify-content: flex-start;
			width: 100%;
			// border-bottom: 1px solid #eeeeee;
			
			li{
				cursor:pointer;
				margin: 5px 10px;
				// padding: 0 10px;
			}
		}

        .el-card {
          width: 125px;
          height: 45px;

          &:hover {
            border: 1px solid $base-color;
            cursor: pointer;
          }
        }

        span {
          margin: auto 25px;
        }
      }
    }

    .table_op {
      margin-top: 5px;
      margin-right: 5px;
    }

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}

.list-wrap {
  overflow: hidden;
}
.goods-recommended {
  width: 200px;
  background-color: #fff;
  float: right;
  margin-left: 10px;
}

.sort {
  display: flex;
  align-items: center;
  
  .item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #f1f1f1;
    border-left: none;
    cursor: pointer;
    &:hover {
      background: $base-color;
      color: #fff;
    }
  }
  .item-other {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #f1f1f1;
    border-left: none;
    cursor: pointer;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    .price_range {
      margin-left: 60px;
    }
    span {
      padding-left: 10px;
    }
    .el-input {
      width: 150px;
      margin-left: 10px;
    }
    .el-button {
      margin: 0 17px;
    }
  }

  >div:first-child {
    border-left: 1px solid #f1f1f1;
  }
}
.goods-info {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 220px;
    margin: 10px 20px 0 0;
    border: 1px solid #eeeeee;
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
    &:nth-child(4n) {
      margin-right: initial !important;
    }
    &:hover {
      border: 1px solid $base-color;
    }
    .img-wrap {
      width: 198px;
      height: 198px;
      cursor: pointer;
      padding: 10px;
    }
    .goods-name {
      padding: 2px 10px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      height: 25px;
      line-height: 25px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: $base-color;
      }
    }
    .goods-selling {
      padding: 0px 10px;
      height: 16px;
      line-height: 16px;
      color: #777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price-wrap {
      padding: 0 10px;
      display: flex;
      align-items: center;
      .price {
        display: flex;
        color: $base-color;
        font-size: $ns-font-size-lg;
        p {
          font-size: $ns-font-size-base;
          display: flex;
          align-items: flex-end;
        }
      }
      .market-price {
        color: #838383;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .comment-wrap {
      padding: 0px 10px;
      display: flex;
      align-items: center;
      height: 20px;
      span{
        padding-left: 6px;
        color: $base-color;
        font-size: $ns-font-size-base;
      }
    }
    .shop_name {
      padding: 0 10px;
      display: flex;
      color: #838383;
    }
    .saling {
      padding: 0px 10px;
      display: flex;
      font-size: $ns-font-size-base;
      line-height: 1;
      .sale-num {
        display: flex;
        color: #838383;
        span {
          color: $base-color;
        }
      }
      .free-shipping {
        background: $base-color;
        color: #ffffff;
        padding: 3px 4px;
        border-radius: 2px;
        margin-right: 5px;
      }
      .is_own {
        color: #ffffff;
        background: #ff850f;
        border: 1px solid;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 3px 4px;
        border-radius: 2px;
      }
      .promotion-type {
        color: $base-color;
        border: 1px solid $base-color;
        display: flex;
        align-items: center;
        padding: 1px 3px;
      }
    }
    .item-bottom {
      display: flex;
      margin-top: 5px;
      .collection {
        flex: 1;
        border: 1px solid #e9e9e9;
        border-right: none;
        border-left: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cart {
        flex: 2;
        border: 1px solid #e9e9e9;
        border-right: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
.empty {
  margin-top: 45px;
}
.pager {
  text-align: center;
  margin-top: 30px;
}


.goods-diy{
	position: absolute;
	right: 0;
	line-height: 25px;
	padding: 2px 5px;
	height: 25px;
	min-width: 40px;
	z-index: 2;
	// border-top-left-radius: 15px;
	// border-bottom-left-radius: 15px;
	text-align: center;
	color: #fff;
}
// 折扣标签
.diy-discount {	
	top: 10px;
	background-color: #F54F3D;
	
	span{
		letter-spacing:1px;
		// padding-left: 4px;
		font-size: 14px;
	}
}

.diy-stock {	
	top: 50px;
	background-color: #75D69C;
	
	span{
		letter-spacing:1px;
		padding-left: 4px;
		font-size: 14px;
	}
}

.goods-left-diy {
	position: absolute;
	left: 0;
	top: 0;
	line-height: 25px;
	padding: 2px 5px 2px 2px;
	height: 25px;
	width: 50px;
	z-index: 1;
	border-radius: 0px 0px 8px;
	text-align: center;
	color: #fff;
}

.diy-own {
	background-color: #EA4D1A;
	
	span{
		letter-spacing:1px;
		font-size: 14px;
		.iconfont {
			font-size: 14px;
		}
	}
}
</style>
