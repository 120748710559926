<template>
	<div class="goods-recommend" v-loading="loading">
		<h4>{{$lang('common.select_product')}}</h4>
		<ul v-if="list.length">
			<li style="position: relative;" v-for="(item, index) in list" :key="index"  @click="$router.pushToTab({ path: '/product-' + item.goods_id + '.html' })">
				<!-- 自定义标签 -->
				<div class="goods-diy diy-discount" v-if="item.price_label">
				    <span>{{item.price_label}}</span>
				</div>
				<div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label">
				    <span>{{item.stock_label}}</span>
				</div>
				<div class="goods-left-diy diy-own" v-if="item.own_label">
					<span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
				</div>
				<div class="img-wrap"><img :src="$img(item['sku_image'], { size: 'mid' })" @error="imageError(index)" /></div>
				<div class="price">{{ siteInfo.price_unit }}{{ item.discount_price }}</div>
				<p class="sku-name">
					<router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name">{{ item.goods_name }}</router-link>
				</p>
				<div class="info-wrap"></div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { goodsRecommend } from '../api/goods/goods';
export default {
	name: 'goods_recommend',
	props: {
		page: {
			type: [Number, String],
			default: 1
		},
		pageSize: {
			type: [Number, String],
			default: 5
		},
		route: {
			type: [String],
			default: 'goodslist'
		},
		skuId: {
			type: [Number, String],
			default: 0
		}
	},
	data: () => {
		return {
			loading: true,
			list: []
		};
	},
	created() {
		this.getGoodsRecommend();
	},
	computed: {
		...mapGetters(['defaultGoodsImage', "siteInfo"])
	},
	methods: {
		getGoodsRecommend() {
			goodsRecommend({
				page: this.page,
				page_size: this.pageSize,
				route: this.route,
				sku_id: this.skuId
			}).then(res => {
				if (res.code == 0) this.list = res.data.list;
				this.loading = false;
			}).catch(res => {
				this.loading = false;
			});
		},
		imageError(index) {
			this.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss" scoped>
.goods-recommend {
	border: 1px solid #eeeeee;
	h4 {
		margin: 10px;
	}
	ul {
		li {
			padding: 10px;
			cursor: pointer;
			.price {
				color: $base-color;
				font-size: 16px;
			}
			.sku-name {
				font-size: $ns-font-size-sm;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			&:hover {
				color: $base-color;
			}
		}
	}
}

.goods-diy{
	position: absolute;
	right: 0;
	line-height: 20px;
	padding: 2px 5px;
	height: 20px;
	min-width: 30px;
	z-index: 1;
	// border-top-left-radius: 15px;
	// border-bottom-left-radius: 15px;
	text-align: center;
	color: #fff;
}
// 折扣标签
.diy-discount {	
	top: 15px;
	background-color: #F54F3D;
	
	span{
		letter-spacing:1px;
		// padding-left: 2px;
		font-size: 11px;
	}
}

.diy-stock {	
	top: 45px;
	background-color: #75D69C;
	
	span{
		letter-spacing:1px;
		padding-left: 2px;
		font-size: 11px;
	}
}
.goods-left-diy {
	position: absolute;
	left: 0;
	top: 0;
	line-height: 25px;
	padding: 2px 5px 2px 2px;
	height: 25px;
	width: 50px;
	z-index: 1;
	border-radius: 0px 0px 8px;
	text-align: center;
	color: #fff;
}
.diy-own {
	background-color: #EA4D1A;
	
	span{
		letter-spacing:1px;
		font-size: 14px;
		.iconfont {
			font-size: 14px;
		}
	}
}
</style>
